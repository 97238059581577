body {
  font: 14px "Century Gothic", Futura, sans-serif;
  background-color:#dedede;
  margin: 0px;
  color: #505050;
  overflow-x:hidden;
}


html{
  margin: 0px;
  padding: 0px;
  overflow-x:hidden;
}

.search-box{
  position: absolute;
  left: 10%;
}


input[type=search]{
  border: none;
  padding: 7%;
  border-radius: 5px;
  color: #333;
  background-color: #ededed;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 10%), 0 2px 10px 0 rgb(0 0 0 / 8%)
}

input[type=search]:focus{
  outline: none;
}

.lmc-title{
  background-color: #eee;
  box-shadow: 0px 0px 4px #ccc;
  padding: 1%;
  margin-bottom: 2%;
}

.grid{
  padding: 1%;
  background-color: #ededed;
  margin-bottom: 2%;
}


.lmc-grid{
  display: grid;
  grid-template-columns: 12.2% 12.2% 12.2% 12.2% 12.2% 12.2% 12.2% 12.2%;
  grid-column-gap:5px;
  grid-row-gap:10px;
  margin: .2%;
  margin-top: 0.5%;
}

.lmc-grid-item{
  background-color: #eee;
  text-align: center;
  height: 132px;
  min-height: 105px;
  max-height: 132px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #ccc;
  padding: 0.1%;
}

.lmc-grid-item-image{
  width: 100%;
  height: auto;
  cursor: pointer;
}

.lmc-grid-item-image img{
  width: 97%;
  height: auto;
  border-radius: 3px;
  margin-top: 2.5%;
}


.large{
  font-size: 3em;
}

.bold{
  font-weight: bold;
}

.pointable{
  cursor: pointer;
  user-select: none;
}

.text-centered{
  text-align: center;
  line-height: 4.3em;
}

.actions{
  display: flex;
  justify-content: flex-end;
}

.lmc-button{
  padding: .75%;
  margin-bottom: 1.5%;
  border-radius: 3px;
  box-shadow: 2px 2px 2px #999;
  cursor: pointer;
  width: 10%;
  text-align: center;
  user-select: none;
  font-size: 1em;
  margin-right: 0.5%;
  border:none;
}

.lmcbtn{
  padding: .75%;
  margin-bottom: 1.5%;
  border-radius: 3px;
  box-shadow: 2px 2px 2px #999;
  cursor: pointer;
  text-align: center;
  user-select: none;
  font-size: 1em;
  margin-right: 0.5%;
  border:none;
}

.green{
  background-color: #2D936C;
  color: white;
}

.gold{
  background-color: #D3AC5E;
  color: white;
}

.red-text{
  color: #DA3E52;
}

.redbg{
  background-color: #DA3E52;
  color: white;
}

.gray{
  color: #444;
}

.white{
  background-color: #efefef;
  color: #191919;
}

.lmc-button:hover{
 box-shadow: none;
}

.flex-parent{
  display: flex;
  flex-direction:row;
  margin-top:1%;
}

.flex-start{
  width: 80%;
  text-align: left;
  padding-left: 5%;
}

.flex-end{
  text-align: right;
  padding: 1%;
  padding-right: 1%;
  display: flex;
  align-items: flex-end;
}

svg{
  cursor: pointer;
}

.flex-end svg{
  margin-left: 15%;
}




.lmc-info{
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 2em;
}


.floating-plus{
  position: fixed;
  right: 15px;
  bottom: 10px;
  font-size: 2.0em;
  background-color: #ededed;
  padding: 1%;
  padding-left: 1.3%;
  padding-right: 1.3%;
  border-radius: 100px;
  user-select: none;
  cursor: pointer;
}

.passive-input{
  width: 92%;
  border: none;
  background-color: #eee;
  caret-color: transparent;
}

.passive-input:focus {
  outline: none;
}





/* TIMELINE */


/*——————————————
Global
———————————————*/

body {
  font-family: helvetica;
}
/*——————————————
TimeLine CSS
———————————————*/
/* Base */

#content {
  text-align: center;
}

section.timeline-outer {
  width: 80%;
  margin: 0 auto;
}

h1.header {
  font-size: 50px;
  line-height: 70px;
}
/* Timeline */

.timeline {
  border-left: 8px solid #a78543;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.08);
  color: #333;
  margin: 50px auto;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  padding: 20px;
  list-style: none;
  text-align: left;
  background-color: #ededed;
  margin-top: 0px;
  border-radius: 5px;
}

.timeline h1,
.timeline h2,
.timeline h3 {
  font-size: 1.4em;
}

.timeline .event {
  border-bottom: 1px solid rgba(160, 160, 160, 0.2);
  padding-bottom: 15px;
  margin-bottom: 20px;
  position: relative;
}

.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .event:before {
  left: -163px;
  color: #777;
  content: attr(data-date);
  text-align: right;
  /*  font-weight: 100;*/
  
  font-size: 15px;
  min-width: 120px;
}

.timeline .event:after {
  box-shadow: 0 0 0 8px #D3AC5E;
  left: -30px;
  background: #ddd;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  top: 5px;
}
/**/
/*——————————————
Responsive Stuff
———————————————*/


@media (max-width: 1600px){
  .lmc-grid-item{
    height: 107px;
  }
}

@media (max-width: 945px) {
  .timeline .event::before {
    left: 0.5px;
    top: 20px;
    min-width: 0;
    font-size: 13px;
    display: none;
  }
  .timeline h3 {
    font-size: 16px;
  }
  .timeline p {
    padding-top: 20px;
  }
  section.lab h3.card-title {
    padding: 5px;
    font-size: 16px
  }
  .lmc-grid{
    grid-template-columns: 33.3% 33.3% 33.3%;
    grid-column-gap:5px;
    grid-row-gap:120px;
  }
  .lmc-grid-item{
    height: 150px;
  }
}


@media (max-width: 768px) {
  .container{
    margin-top: 5%;
  }
  .search-box{
    left: 2.5%;
  }
  .actions{
    margin-left: 5%;
  }
  .timeline{
    width: 90%;
  }
  section.timeline-outer{
    width: 87%;
  }
  .timeline .event::before {
    left: 0.5px;
    top: 20px;
    min-width: 0;
    font-size: 13px;
    display: none;
  }
  .timeline .event:nth-child(1)::before,
  .timeline .event:nth-child(3)::before,
  .timeline .event:nth-child(5)::before {
    top: 38px;
  }
  .timeline h3 {
    font-size: 16px;
  }
  .timeline p {
    padding-top: 20px;
  }
  .lmc-grid{
    grid-template-columns: 50% 50%;
    grid-row-gap: 50px;
  }
  .lmc-grid-item{
    height: 110px;
  }
  .lmc-button{
    width: 50%;
    padding: 1.5%;
  }
  .lmc-info{
    top: 0px;
    right:0px;
  }
  .file-container{
    flex-direction: column;
  }
  .file-preview{
    width: 100%;
    height: auto;
  }
}
/*——————————————
others
———————————————*/

a.portfolio-link {
  margin: 0 auto;
  display: block;
  text-align: center;
}



.modal{
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color:rgba(255, 255, 255, 0.95);
  position: fixed;
  left: 0px;
  top: 0px;
}

.modal-close{
  margin-top: 1%;
  width: 98.5%;
  text-align: right;
  font-size: 2em;
}

.modal-content{
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
  padding: 1%;
  border-radius: 3px;
  background-color: rgba(245, 245, 245, 0.85);
  height: 75%;
  margin-bottom: 2%;
  overflow-y: scroll;
}

.subtitle{
  font-size: 2em;
  color: #666;
  margin-bottom: 1%;
}

.smalltitle{
  font-size: 1.5em;
  color: #656565;
  margin-bottom: .5%;
}

.modal-footer{
  align-items: right;
  text-align: right;
  margin-right: 10%;
}

.textarea{
  width: 95%;
  margin-left: 1%;
  margin-top: 1%;
  height: 200px;
  border-radius: 5px;
  font-size: 1.2em;
  background-color: #fefefe;
  border: none;
  padding: 1%;
  resize: none;
}

.textarea:focus{
  outline: none;
}

textarea::placeholder{
  color: #bbb;
}

.selected-files{
  display: grid;
  grid-template-columns: 24% 24% 24% 24% 24%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 1%;
}

.selected-file{
  padding: 2%;
  text-align: center;
  border-radius: 3px;
}


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa; 
}

.error-message{
  margin-bottom: 1%;
}

.file-container{
  width: 100%;
  justify-content: center;
  display: flex;
}

.file-preview{
  height: auto;
  width: 100%;
  margin-top: 4%;
}

.file-info{
  width: 100%;
  height: 100%;
  padding: 1%;
}

.file-preview img{
  width: 100%;
  margin-bottom: 2%;
}

.file-preview video{
  width: 100%;
  margin-bottom: 2%;
}


h3 span{
  font-size: .9vw;
  color: #999;
  font-weight: normal;
}

.lmc-thumb{
  position: relative;
}


.thumb-play-icon{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  background-color: #fefefe;
  border-radius: 100px;
  padding: 1%;
}


.lds-ring {
  /*display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;*/
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: fixed;
  width: 64px;
  height: 64px;
  border: 8px solid #D3AC5E;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #D3AC5E transparent transparent transparent;
  right: 5px;
  bottom: 5px;
  z-index: 10;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.level{
  display: flex;
  width: 100%;
  padding: 1%;
  justify-content: space-evenly;
}